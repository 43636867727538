import React, { useEffect } from 'react';
import {
  ConnectedWizardStep, setWizardStepAlertMessage, WizardStepLocatorsMap,
} from '@app/common/configurable-wizards';
import { getNotFoundError } from '@app/core/widgets/pages';
import { useWizardStepStateContext } from '@app/common/configurable-wizards/WizardStateStateContext';
import { isApolloError } from '@apollo/client';
import { useWizardStepStateDispatchContext } from '@app/common/configurable-wizards/WizardStateDispatchContext';
import { useTranslation } from 'react-i18next';
import { genericErrorHandler } from '@app/common/errors';
import { useNetworkInformation } from '@app/core/hooks/useNetworkInformation/useNetworkInformation';
import { errorsToAlert } from '@app/common/configurable-wizards/utils/wizardAlertError';

export interface WizardStepMediatorProps {
  readonly steps: WizardStepLocatorsMap;
  // eslint-disable-next-line max-len
  readonly wizardStepProps: Pick<ConnectedWizardStep, 'onSubmit' | 'onBack' | 'onCancel' | 'onTerminationStep' | 'wizard'>
}

/**
 * Mediator that places between wizard and its step.
 * It should hold all common universal logic of the wizards,
 * like generic error handling, preparing and applying the wizard step, etc.
 *
 * So, put here all common logic between different wizards, that may be performed before wizard-step rendering.
 */
export const WizardStepMediator: React.FC<WizardStepMediatorProps> = ({
  wizardStepProps,
  steps,
}) => {
  const { t } = useTranslation();
  const { isOnline } = useNetworkInformation();
  const dispatch = useWizardStepStateDispatchContext();
  const { id, genericErrors, unhandledErrors } = useWizardStepStateContext();

  // generic errors processing
  useEffect(() => {
    if (genericErrors) {
      const alertError = errorsToAlert(genericErrors);
      dispatch(setWizardStepAlertMessage(alertError));
    }
  }, [genericErrors]);

  // unhandled errors processing
  useEffect(() => {
    unhandledErrors?.forEach((error) => {
      // No internet connection errror.
      // In that case, just show a local error message instead of emitting to error boundary
      if (isApolloError(error) && error.networkError && !isOnline) {
        dispatch(setWizardStepAlertMessage({
          title: t(
            'configurable-wizards.wizard-step.submit-form.network-error.title|The title related to error message that would be shown when we get network errors during form submitting.',
            'Network Error',
          ),
          content: t(
            'configurable-wizards.wizard-step.submit-form.network-error.message|This error message shows when we get network errors during form submitting.',
            'Your internet connection is unstable. Please try again when you\'re back online.',
          ),
          type: 'error',
        }));
      } else {
        // throw other unhandled errors
        throw genericErrorHandler(error);
      }
    });
  }, [unhandledErrors]);

  // do not render step if not identified
  if (!id) return null;

  // try to found step
  const Component = steps.get(id);
  if (!Component) {
    throw getNotFoundError();
  }

  return (
    <Component
      onSubmit={wizardStepProps.onSubmit}
      onBack={wizardStepProps.onBack}
      onCancel={wizardStepProps.onCancel}
      wizard={wizardStepProps.wizard}
      onTerminationStep={wizardStepProps.onTerminationStep}
    />
  );
};
