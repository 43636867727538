import fetchIntercept from 'fetch-intercept';

import {
  handleRequest, handleResponse,
  OperationHandler, RequestTiming,
} from '@ast/opentelemetry';

import { onLocationChange } from '@app/common/utils/overrideHistoryMethod';

import { spanManager, tracing } from '@app/core/opentelemetry/setupTracing';

export type InitSpanHandlersArgs = Record<string, OperationHandler>;

export const initSpanHandlers = (handlers: InitSpanHandlersArgs) => {
  onLocationChange(() => {
    spanManager.startNewRootSpan();
  });

  spanManager.startNewRootSpan();

  return fetchIntercept.register({
    request(url: string, config) {
      return handleRequest({
        handlers,
        url,
        config,
        spanManager,
      });
    },

    response(response) {
      return handleResponse({
        tracing,
        handlers,
        response,
        spanManager,
      });
    },
  });
};

export enum Flows {
  LoginFlow= 'login-flow',
}

export const registerOpentelemetrySpans = () => {
  const handlers = {
    [Flows.LoginFlow]: new OperationHandler({
      requestTiming: new RequestTiming(sessionStorage),
      startOperationName: 'PostLoginStep',
      endOperationName: 'GetBankAccounts',
    }),
  };

  return initSpanHandlers(handlers);
};
