import React from 'react';
import { useTranslation } from 'react-i18next';

import Bell from '@ast/magma/components/icon/icons/Bell';
import BellRing from '@ast/magma/components/icon/icons/BellRing';

import { Badge } from '@ast/magma/components/badge';
import { Button } from '@ast/magma/components/button';
import { DropDownMenuProps } from '@ast/magma/components/dropdownmenu';

import styles from './MessageNotificationMenuButton.pcss';

export const MessageNotificationMenuButton = React.forwardRef((
  { unreadTotal, ...props }: Partial<DropDownMenuProps> & { unreadTotal: number },
  ref: React.Ref<HTMLButtonElement>,
) => {
  const { t } = useTranslation();

  return (
    <Badge
      aria-label={t(
        'message-notification-menu-button.badge.aria|Top menu notifications button unread count badge',
        'You have {{number}} unread notifications', { number: unreadTotal },
      )}
      max={9}
      color="error"
      variant="counter"
      display="filled"
      data-stable-name="TotalUnreadMessages"
      count={unreadTotal}
    >
      <Button
        ref={ref}
        look="icon"
        className={styles.icon}
        aria-label={t(
          'message-notification-menu-button.button.aria|Top menu notifications button label',
          'Notifications',
        )}
        icon={unreadTotal > 0 ? <BellRing /> : <Bell />}
        {...props}
      />
    </Badge>
  );
});
