import * as React from 'react';

import { ColorTheme } from '@app/common/types/ColorTheme';

import { joinStoragePath } from '@app/core/storage/browser/utils';

import { createGenericContext } from '../../../common/contexts/createGenericContext';

export interface ColorThemeContext {
  colorTheme:ColorTheme;
  changeColorTheme:(value:ColorTheme) => void;
}

/**
 * Context to query application widgets.
 */
const [useColorThemeContext, ColorThemeContextProvider] = createGenericContext<ColorThemeContext>();

// Color theme must be initialized in entry index html
// eslint-disable-next-line i18next/no-literal-string
const readColorTheme = () => (document.body.getAttribute('data-theme') as ColorTheme);
const writeColorTheme = (value:ColorTheme) => {
  document.body.setAttribute('data-theme', value);
  // Save selected color theme in local storage
  // eslint-disable-next-line i18next/no-literal-string
  localStorage.setItem(joinStoragePath('color-theme'), value.toString());
};

/**
 * Application widgets context provider.
 */
const ColorThemeProvider: React.FC = ({ children }) => {
  const [colorTheme, setColorTheme] = React.useState<ColorTheme>(readColorTheme());
  const changeColorTheme = React.useCallback((value:ColorTheme) => {
    writeColorTheme(value);
    setColorTheme(value);
  }, []);

  const value = React.useMemo(
    () => ({ colorTheme, changeColorTheme }),
    [colorTheme, changeColorTheme],
  );

  return (
    <ColorThemeContextProvider value={value}>
      {children}
    </ColorThemeContextProvider>
  );
};

export { useColorThemeContext, ColorThemeProvider };
