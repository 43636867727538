import { appSettings } from '@app/core/appSettings';
import { LocalSettings } from '@app/core/storage/browser/LocalSettings';

import { getAuthenticationToken } from '../authenticationToken';

import { getDSSessionId } from '../customAuthHeaders/dsSessionId';

import { TETHERED_LOGIN_API_BASE } from './const';
import { SharedSessionData } from './types';

/**
 * Represents api request
 */
export type ApiRequest = SharedSessionData;

/**
 * Represents api response
 */
export type ApiResponse = {
  /**
   * An excange token that will be used to get session parameters after redirection from the iframe to the top window
   */
  readonly token: string;
};

/**
 * Tethered login redirect url parameter, used to pass token to the top window
 */
export const TETHERED_LOGIN_REDIRECT_URL_PARAM = 'tlr';
export const API_ACTION_URL = '/initredirect';

/**
 * @private
 */
export const getRedirectUrl = (path: string, token: string) => (
  `${window.location.origin}${path}?${TETHERED_LOGIN_REDIRECT_URL_PARAM}=${encodeURIComponent(token)}`
);

/**
 * @private
 */
export const getApiUrl = () => (
  `${appSettings().apiEndpoint}${TETHERED_LOGIN_API_BASE}${API_ACTION_URL}`
);

/**
 * @private
 */
export const getRequestBody = (): ApiRequest => ({
  authToken: getAuthenticationToken(),
  clientDeviceUID: LocalSettings.instance.getClientDeviceID() || '',
  dsSessionID: getDSSessionId(),
});

/**
 * @private
 */
export const getRequestOptions = (body: any): RequestInit => ({
  method: 'POST',
  headers: {
    // eslint-disable-next-line i18next/no-literal-string
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});

/**
 * 1. Puts to the server the session data that should be shared between iframe and top window.
 * 2. Gets back the token that required to get session parameters after redirection from the iframe to the top window
 * 3. Finally, redirects to the top window with exchange token in the url
 */
export const tetheredLoginRedirect = (path: string) => {
  const body = getRequestBody();
  const requestOptions = getRequestOptions(body);
  const url = getApiUrl();

  return fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error calling the API');
      }
      return response.json();
    })
    .then(({ token }: ApiResponse) => {
      // Redirect to top window
      window.parent.location.href = getRedirectUrl(path, token);
    })
    .catch((error) => {
      throw error;
    });
};
