import { SpanManager, Tracing } from '@ast/opentelemetry';

import { appSettings } from '@app/core/appSettings';
import { removeCorrSessionId } from '@app/core/authentication/customAuthHeaders/corrSessionId';

export {
  useSetTraceSpan, X_AMAZON_TRACE_ID_KEY, context, Span, trace,
} from '@ast/opentelemetry';

const otlpCollectorEnabled = Boolean(appSettings().otlpCollectorEnabled === 'true') && appSettings().otlpCollectorUrl;

export const getTracingOptions = () => ({
  serviceName: 'ofm-fe',
  isDebugEnabled: false,
  samplingRatio: 0.07,
  exporterUrl: otlpCollectorEnabled ? appSettings().otlpCollectorUrl : '',
  exportMetricsIntervalMillis: 60000,
  metricConcurrencyLimit: 10,
  resourceAttrs: {
    'fi.name': appSettings().loggerFiId,
    'deployment.environment': appSettings().loggerExecEnvironment,
  },
});

export const tracing = new Tracing(getTracingOptions());

export const spanManager = new SpanManager(tracing);

export const getTraceId = () => {
  const traceId = spanManager.getRootSpan()?.spanContext().traceId;
  const spanId = spanManager.getRootSpan()?.spanContext().spanId;
  return {
    traceId,
    spanId,
  };
};

export const getAmazonTraceId = (
  traceId: string,
  spanId: string,
) => `Root=1-${traceId.slice(0, 8)}-${traceId.slice(8)};Parent=${spanId};Sampled=1`;

export const parseAmazonTraceId = (str: string) => ({
  traceId: str.slice(7, 40).replace(/-/, ''),
  spanId: str.slice(48, 64),
});

removeCorrSessionId();
