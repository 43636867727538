import dayjs from 'dayjs';

import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

// Extend dayjs library with plugin to work with duration
dayjs.extend(duration);

// Extend dayjs library with plugin to work with local formats
dayjs.extend(localizedFormat);

// Extend dayjs library with plugin to work with UTC
dayjs.extend(utc);
