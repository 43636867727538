import { setSessionValue, getSessionValue, removeSessionValue } from '@app/core/storage/browser/sessionStorage';

/**
 * Event type fired when auth token has been updated.
 */
export const AUTH_TOKEN_UPDATE_EVENT = 'ast.authTokenUpdate';

export const TOKEN_STORAGE_KEY = 'auth-token';

export const setAuthenticationToken = (token: string): void => {
  setSessionValue(TOKEN_STORAGE_KEY, token);
  window.dispatchEvent(new CustomEvent(AUTH_TOKEN_UPDATE_EVENT, { detail: { token } }));
};

/**
 * Returns authentication token from session storage.
 * @returns uthentication token
 */
export const getAuthenticationToken = (): string => getSessionValue(TOKEN_STORAGE_KEY);

export const resetAuthenticationToken = (): void => {
  const token = getAuthenticationToken();
  const isTokenSet = Boolean(token);

  // do not dispatch event if token is not set
  if (!isTokenSet) {
    return;
  }

  removeSessionValue(TOKEN_STORAGE_KEY);
  window.dispatchEvent(new CustomEvent(AUTH_TOKEN_UPDATE_EVENT, { detail: { token } }));
};

export default {
  setAuthenticationToken,
  getAuthenticationToken,
  resetAuthenticationToken,
  AUTH_TOKEN_UPDATE_EVENT,
  TOKEN_STORAGE_KEY,
};
