import React from 'react';

import { Button } from '@ast/magma/components/button';

import { FlexContainer } from '@app/common/components/FlexContainer/FlexContainer';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import styles from './Buttons.pcss';

export const Buttons: React.FC = () => {
  const { t } = useUserLoginTranslation();

  return (
    <FlexContainer gap="small">
      <Button
        type="submit"
        className={styles.signInButton}
        look="primary"
        predefinedSize="medium"
        data-stable-name="SignInButton"
      >
        {t('login.steps.username-password.buttons.signin.text|Sign in button text', 'Sign in')}
      </Button>
    </FlexContainer>
  );
};
