import { useRef, useEffect } from 'react';

export const useDocumentTitle = (title: string, retainOnUnmount = false, rewritePreviousTitle = false) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    if (rewritePreviousTitle) {
      defaultTitle.current = document.title;
    }
    document.title = title;
  }, [title]);

  useEffect(() => () => {
    if (!retainOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, []);
};
