import { client } from '@app/core/apolloClient';


import {
  InvokeExternalApiIntegrationDocument,
  InvokeExternalApiIntegrationQuery,
  InvokeExternalApiIntegrationQueryVariables,
} from '../../queries/queryTyping/invokeExternalApiIntegration';

import { RequestHandler } from '../types';
import { InvokeExternalApiIntegrationRequest, isInvokeExternalApiIntegrationRequest } from './request';
import { InvokeExternalApiIntegrationResponse, invokeExternalApiIntegrationResponseType } from './response';

/**
 * Supported request versions.
 */
export const supportedRequestVersions: Set<number | string> = new Set(["1.0"]);

/**
 * Handler response version.
 */
export const currentResponseVersion = "1.0";
export const currentPayloadVersion = "1.0";

/**
 * Invoke External Api Integration handler calls InvokeExternalAPI component via GraphQL.
 *
 * This allows Custom Apps to perform the calls external API calls without the direct access to backend.
 */
// eslint-disable-next-line max-len
export const invokeExternalApiIntegrationHandler: RequestHandler<InvokeExternalApiIntegrationRequest, InvokeExternalApiIntegrationResponse> = async (request) => {
  if (!isInvokeExternalApiIntegrationRequest(request)) {
    // eslint-disable-next-line i18next/no-literal-string
    throw new Error('Bad InvokeExternal Api Integration request.');
  }

  if (!supportedRequestVersions.has(request.version)) {
    // eslint-disable-next-line i18next/no-literal-string
    throw new Error('Unsupported component request version.');
  }

  const { data, error } = await client.query<InvokeExternalApiIntegrationQuery, InvokeExternalApiIntegrationQueryVariables>({
    query: InvokeExternalApiIntegrationDocument,
    fetchPolicy: 'no-cache',
    variables: {
      integrationName: request.payload.integrationName,
      arguments: Object.entries(request.payload.args).map(([name, value]) => ({ name, value }))
    },
  });


  if (error) {
    throw error;
  }

  const payload = data.invokeExternalApiIntegration;
  if (!payload) {
    // eslint-disable-next-line i18next/no-literal-string
    throw new Error('No payload received.');
  }

  return {
    id: request.id,
    type: invokeExternalApiIntegrationResponseType,
    version: currentResponseVersion,
    payloadVersion: currentPayloadVersion,
    payload: {
      result: payload.result,
      successful: payload.successful,
      errors: payload.errors,
      warnings: payload.warnings,
      infos: payload.infos,
    },
  };
};
