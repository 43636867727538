import React from 'react';
import { Button } from '@ast/magma/components/button';
import { WizardButtonComponent, WizardButtonProps } from '../types';

export const ContinueButton: WizardButtonComponent<WizardButtonProps> = ({ title, disabled }) => (
  <Button
    type="submit"
    look="primary"
    predefinedSize="medium"
    data-stable-name="ContinueButton"
    disabled={disabled ?? false}
  >
    {title}
  </Button>
);
