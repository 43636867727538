import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PinInput } from '@ast/magma/components/pininput';

import { FieldBehaviorMode } from '@app/queryTyping';

import { useCommonValidationMessages } from '@app/common/valiation/commonValidationMessages';

import { TextFieldFragment as TextField } from '../../queries/queryTyping/all-fields';
import {
  FieldComponent,
  FieldLocator,
} from '../../types';
import {
  combineNames,
  getFieldErrorMessage,
  isTextField,
} from '../../utils';
import { CFFormField } from '../common/CFFormField';

import styles from './PinField.pcss';

export const PinFieldComponent: FieldComponent<TextField> = ({
  parent,
  field,
  fieldProps,
}) => {
  const resetMargin = fieldProps?.resetMargin;
  const { control, formState: { errors } } = useFormContext();
  const name = combineNames(parent, field.id);
  const commonValidationMessages = useCommonValidationMessages();
  const error = getFieldErrorMessage(name, errors, commonValidationMessages);
  const length = field.maxLength;
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      rules={
        {
          required: field.fieldBehaviorMode === FieldBehaviorMode.REQUIRED,
          validate: {
            complete: (v = '') => (
              v.length >= length || t(
                'pin-field.validation.incomplete-msg|Incompete PIN code validation message',
                'Please complete the field',
              ).toString()
            ),
          },
        }
      }
      render={(renderData) => (
        <CFFormField error={error} className={styles.formField}>
          <PinInput
            type="numeric"
            length={length}
            className={resetMargin ? styles.pinFieldInitialMargin : styles.pinField}
            data-stable-name={`${field.id}Input`}
            invalid={renderData.fieldState.invalid}
            {...renderData.field}
          />
        </CFFormField>
      )}
    />
  );
};

export const PinFieldFieldLocator: FieldLocator = ({ field }) => (
  isTextField(field) && field.id === 'OneTimePIN'
    ? PinFieldComponent
    : undefined
);
