import { useEffect, useMemo } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FieldBehaviorMode } from '@app/queryTyping';

import { ConstrainedTextField } from '@app/common/configurable-wizards';
import { useCommonValidationMessages } from '@app/common/valiation/commonValidationMessages';

import { FieldRenderData } from '../../types';
import {
  combineNames,
  getFieldErrorMessage,
} from '../../utils';

export type UseConstrainedTextFieldProps = Omit<FieldRenderData<ConstrainedTextField>, 'locator'>;
export type UseConstrainedTextFieldResult = {
  readonly name: string;
  readonly error: string;
  readonly registerOptions: RegisterOptions;
};

export const useConstrainedTextField = ({
  parent,
  field,
}: UseConstrainedTextFieldProps): UseConstrainedTextFieldResult => {
  const { t } = useTranslation();
  const { unregister, formState: { errors } } = useFormContext();
  const name = combineNames(parent, field.id);
  const commonValidationMessages = useCommonValidationMessages();
  const error = getFieldErrorMessage(name, errors, commonValidationMessages);

  useEffect(() => () => unregister(name), []);

  const registerOptions = useMemo<RegisterOptions>(() => {
    const options: RegisterOptions = {
      required: field.fieldBehaviorMode === FieldBehaviorMode.REQUIRED,
      maxLength: field.maxLength,
      minLength: field.minLength,
    };

    if (field.validationRegExp) {
      options.pattern = {
        value: new RegExp(field.validationRegExp),
        message: field.validationMessage || (field.label && t(
          'constrained-text-input.validation.pattern|Pattern validation message for constrained text input',
          '{{fieldLabel}} is not valid',
          { fieldLabel: field.label },
        )) || '',
      };
    }

    return options;
  }, [field]);

  return {
    name,
    error,
    registerOptions,
  };
};
