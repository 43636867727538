import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PatternFormat } from 'react-number-format';

import dayjs from 'dayjs';

import { Input } from '@ast/magma/components/input';

import { FieldBehaviorMode } from '@app/queryTyping';

import { FieldComponent, FieldLocator, isDateField } from '@app/common/configurable-wizards';
import { DateFieldFragment } from '@app/common/configurable-wizards/queries/queryTyping/all-fields';
import { combineNames, getFieldErrorMessage } from '@app/common/configurable-wizards/utils';
import { DEFAULT_DATE_TIME_FORMAT_OPTIONS, DEFAULT_LOCALES } from '@app/common/types/locales';
import { useCommonValidationMessages } from '@app/common/valiation/commonValidationMessages';

import { DateInput } from './DateInput';
import { getDateFromString } from './getDateFromString';

/**
 * Convert date string into MM/DD/YYYY format
 * @param val provides string with date
 * @returns string in MM/DD/YYYY format
 * Used for field validation.
 */
const dateToShortString = (val: string) => (new Date(val))
  .toLocaleDateString(DEFAULT_LOCALES, DEFAULT_DATE_TIME_FORMAT_OPTIONS);

/**
 * Represents the DateField configurable field.
 *
 * Used for Birthday field of User Enrollment step Establish Identity.
 */
export const DateFieldComponent: FieldComponent<DateFieldFragment> = (props) => {
  const { t } = useTranslation();
  const {
    field,
    parent,
  } = props;

  const name = combineNames(parent, field.id);
  const { formState: { errors } } = useFormContext();
  const commonValidationMessages = useCommonValidationMessages();
  const error = getFieldErrorMessage(name, errors, commonValidationMessages);

  const dateStringFormat = 'MM/DD/YYYY';
  const dateStringLength = dateStringFormat.length;

  const rules = {
    required: field.fieldBehaviorMode === FieldBehaviorMode.REQUIRED,

    validate: {
      isValid: (value: string = '') => {
        const valueWithoutSpaces = value.replaceAll(' ', '');
        const date = getDateFromString(value);
        const isValid = date.isValid();

        return (valueWithoutSpaces.length < dateStringLength || !isValid) ? t(
          'date-field.validation.pattern|Validation date format',
          'Please enter correct date ({{fmt}})',
          { fmt: dateStringFormat },
        ).toString() : true;
      },

      minValue: (value: string) => {
        const current = getDateFromString(value);
        const min = dayjs(field.dMinValue);

        // eslint-disable-next-line i18next/no-literal-string
        return (current.isSame(min, 'date') || current.isBefore(min, 'date'))
          ? t(
            'date-field.validation.min-value|Validation message for min-value',
            'Field value should be greater than {{value}}',
            { value: dateToShortString(field.dMinValue) },
          ).toString()
          : true;
      },

      maxValue: (value: string) => {
        const current = getDateFromString(value);
        const max = dayjs(field.dMaxValue);

        // eslint-disable-next-line i18next/no-literal-string
        return (current.isSame(max, 'date') || current.isAfter(max, 'date'))
          ? t(
            'date-field.validation.max-value|Validation message for max-value',
            'Field value should be less than {{value}}',
            { value: dateToShortString(field.dMaxValue) },
          ).toString()
          : true;
      },
    },
  };

  return (
    <DateInput
      controllerProps={{
        render: (renderProps) => {
          const { ref, ...renderFiled } = renderProps.field;

          return (
            <PatternFormat
              format="##/##/####"
              customInput={Input}
              label={field.label || ''}
              data-stable-name={`${field.id}Input`}
              aria-invalid={!!error}
              autoComplete="on"
              getInputRef={ref}
              {...renderFiled}
            />
          );
        },
        rules,
      }}
      name={name}
      error={error}
      {...props}
    />
  );
};

export const DateFieldLocator: FieldLocator = ({ field }) => (
  isDateField(field)
    ? DateFieldComponent
    : undefined
);
