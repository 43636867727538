import React from 'react';

import { getCorrSessionId } from '@app/core/authentication/customAuthHeaders/corrSessionId';
import { getDSSessionId } from '@app/core/authentication/customAuthHeaders/dsSessionId';

import styles from './MetaInfo.pcss';

export const MetaInfo = () => {
  const sessionId = getDSSessionId();
  const { traceId } = getCorrSessionId();

  return (
    <p className={styles.wrapper} data-stable-name="MetaInfo">
      {
        traceId && (
        <span data-stable-name="MetaInfoCorrelationId">
          Correlation ID:
          {' '}
          { traceId }
        </span>
        )
      }
      <br />
      {
        sessionId && (
        <span data-stable-name="MetaInfoSessionId">
          Session Id:
          {' '}
          { sessionId }
        </span>
        )
      }
    </p>
  );
};
