import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from '@ast/magma/components/input';

import { FieldBehaviorMode } from '@app/queryTyping';

import { getFieldErrorMessage } from '@app/common/configurable-wizards/utils';
import { useCommonValidationMessages } from '@app/common/valiation/commonValidationMessages';

import { Fields } from '../../types';
import { CFFormField } from '../common/CFFormField';

export interface EmailInputProps {
  field: Fields['Email'];
  name: string;
  onChangeAction?: (value: string) => void;
  containerClassName?: string;
  fieldClassName?: string;
}

export const EmailInput:React.FC<EmailInputProps> = React.memo(({
  name,
  field,
  onChangeAction,
  containerClassName,
  fieldClassName,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();
  const required = field.fieldBehaviorMode === FieldBehaviorMode.REQUIRED;
  const isReadOnly = field.fieldBehaviorMode === FieldBehaviorMode.READONLY || !field.isEnabled;
  const commonValidationMessages = useCommonValidationMessages();
  const error = getFieldErrorMessage(name, errors, commonValidationMessages);

  const options = {
    required,
    pattern: {
      value: RegExp(field.validationRegExp || ''),
      message: t(
        'dialog.email.validation.pattern|Email pattern input validation message',
        'Please enter a valid email address',
      ),
    },
    minLength: {
      value: field.minLength,
      message: t(
        'dialog.email.validation.minlength|Minimum length validation message for email',
        'Email must be more than {{minLength}} characters long',
        { minLength: field.minLength },
      ),
    },
    maxLength: {
      value: field.maxLength,
      message: t(
        'dialog.email.validation.maxlength|Maximum length validation message for email',
        'Email must be {{maxLength}} characters or less',
        { maxLength: field.maxLength },
      ),
    },
  };

  const { onChange, onBlur, ...registerOptions } = register(name, isReadOnly ? undefined : options);

  return (
    <CFFormField
      className={containerClassName}
      error={error}
    >
      <Input
        autoFocus
        className={fieldClassName}
        minLength={isReadOnly ? undefined : field.minLength}
        maxLength={isReadOnly ? undefined : field.maxLength}
        label={field.label || ''}
        data-stable-name={`${field.id}Input`}
        aria-invalid={!!error}
        type="text"
        defaultValue={field.sValue || ''}
        readOnly={field.fieldBehaviorMode === FieldBehaviorMode.READONLY}
        onChange={(e) => {
          onChangeAction?.(e.target.value);
          onChange(e);
        }}
        onBlur={(e) => {
          // we have to remove all spaces from both ends before the validation. (According to our validation rules)
          e.target.value = e.target.value.trim();
          onBlur(e);
        }}
        {...registerOptions}
      />
    </CFFormField>
  );
});
