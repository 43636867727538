import React from 'react';
import { Redirect } from 'react-router-dom';

import { PageURL } from '@app/core/widgets/pages';

import {
  ConnectedWizardStep, HiddenField, isHiddenField,
} from '@app/common/configurable-wizards';
import { useWizardStepStateContext } from '@app/common/configurable-wizards/WizardStateStateContext';

import {
  QuickLoginSetupMode, QuickLoginSetupState, QuickLoginUserStatus, WizardSteps,
} from '../../constants';

import { QuickLoginStateWizardStep } from './QuickLoginStateWizardStep';

export const QuickLogin: React.FC<ConnectedWizardStep> = ({ locators, wizard, ...restParams }) => {
  const {
    fields,
  } = useWizardStepStateContext();
  const userStatusField = fields.find((field) => isHiddenField(field) && field.id === 'UserStatus');
  const fieldValue = (userStatusField as HiddenField).sValue as QuickLoginUserStatus;

  return (
    <>
      {fieldValue === QuickLoginUserStatus.BiometricAuthenticationIsAvailable && (
      <QuickLoginStateWizardStep
        locators={locators}
        wizard={wizard}
        {...restParams}
      />
      )}
      {fieldValue !== QuickLoginUserStatus.BiometricAuthenticationIsAvailable && (
        <Redirect
          to={{
            pathname: `${PageURL.USER_LOGIN}/${WizardSteps.QuickLoginSetup}`,
            state: {
              // eslint-disable-next-line max-len
              setupMode: fieldValue === QuickLoginUserStatus.NoBiometricDevice ? QuickLoginSetupMode.InstallationRequired : QuickLoginSetupMode.BiometricSetupRequired,
            } as QuickLoginSetupState,
          }}
        />
      )}
    </>
  );
};
