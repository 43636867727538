import React from 'react';
import { Button } from '@ast/magma/components/button';
import { WizardButtonComponent, WizardButtonProps } from '../types';

export interface BackButtonProps extends WizardButtonProps {
  back: () => void;
}

export const BackButton: WizardButtonComponent<BackButtonProps> = ({ title, back }) => (
  <Button
    type="button"
    look="outlined"
    predefinedSize="medium"
    data-stable-name="BackButton"
    onClick={back}
  >
    {title}
  </Button>
);
