import { getSessionValue, setSessionValue, removeSessionValue } from '@app/core/storage/browser/sessionStorage';

export const CORR_TRACE_ID = 'trace-id';
export const CORR_SPAN_ID = 'span-id';

export const setCorrSessionId = (traceId: string, spanId: string) => {
  setSessionValue(CORR_TRACE_ID, traceId);
  setSessionValue(CORR_SPAN_ID, spanId);
};

export const getCorrTraceId = () => {
  return getSessionValue(CORR_TRACE_ID)
};

export const getCorrSpanId = () => {
  return getSessionValue(CORR_SPAN_ID)
};

export const getCorrSessionId = () => {
  const traceId = getSessionValue(CORR_TRACE_ID);
  const spanId = getSessionValue(CORR_SPAN_ID);

  return {
    traceId,
    spanId,
  };
};

export const removeCorrSessionId = () => {
  removeSessionValue(CORR_TRACE_ID);
  removeSessionValue(CORR_SPAN_ID);
};

export default {
  getCorrSpanId,
  getCorrTraceId,
  getCorrSessionId,
  removeCorrSessionId,
  CORR_TRACE_ID,
  CORR_SPAN_ID,
};
