import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ExclamationCircle from '@ast/magma/components/icon/icons/ExclamationCircle';

import { useToast } from '@ast/magma/components/toast';

import { CompromisedCredentialsStatus } from '@app/queryTyping';

import { ToasButton } from '@app/common/components/ToastButton';
import { useCheckCompromisedCredentialsLazyQuery } from '@app/common/configurable-wizards/queries/queryTyping/compromised-credentials-result';
import { useGetSecuritySettingsLazyQuery } from '@app/common/configurable-wizards/queries/queryTyping/getSecuritySettings';

import { AUTH_TOKEN_UPDATE_EVENT } from '@app/core/authentication/authenticationToken';
import { getSessionValue, removeSessionValue, setSessionValue } from '@app/core/storage/browser/sessionStorage';

import { getPathToPasswordChange } from '@app/widgets/contacts-and-settings/utils/paths';

export const USER_ATTRACTED_TO_ALERT = 'compromised-password.user-attracted-to-alert';

/**
 * The abstracted hook to check if the user's password is compromised after login.
 * If so, it will display a warning alert to the user.
 */
export const useCompromisedCredentialCheckAfterLogin = (isLoggedIn: boolean) => {
  const { add: addToast, remove: removeToast } = useToast();
  const history = useHistory();

  const [checkCompromisedPassword] = useCheckCompromisedCredentialsLazyQuery({
    onCompleted: ({ credentialsStatus: { status } }) => {
      if (status === CompromisedCredentialsStatus.COMPROMISED) {
        const toastId = addToast({
          // eslint-disable-next-line i18next/no-literal-string
          level: 'warn',
          // eslint-disable-next-line i18next/no-literal-string
          position: 'bottom',
          icon: <ExclamationCircle />,
          timeout: 0,
          // instead of plain text, we add html content in children
          title: '',
          canClose: true,
          children: (
            <Trans
              i18nKey="compromised-credentials.compromised-password-notification|The toast notification that appears right after the user logs in if their password is compromised"
            >
              <div>
                Your password appears on a list of known data breaches.
                We strongly encourage you to change it to keep your account secure.
              </div>
              <ToasButton
                style={{ marginTop: 'var(--space-sm)' }}
                onClick={() => {
                  removeToast(toastId);
                  history.push(getPathToPasswordChange());
                }}
              >
                Change password
              </ToasButton>
            </Trans>
          ),
          onToastClose: () => {
            // eslint-disable-next-line i18next/no-literal-string
            setSessionValue(USER_ATTRACTED_TO_ALERT, 'true');
          },
        });
      }
    },
  });

  // 2. Check if the user's password is compromised
  // - after the security settings are fetched
  // - and the feature is enabled
  const [getSecuritySettings] = useGetSecuritySettingsLazyQuery({
    onCompleted: ({ securitySettings: { compromisedCredentialsCheckEnabled } }) => {
      if (compromisedCredentialsCheckEnabled) {
        checkCompromisedPassword();
      }
    },
  });

  const userAttractedToAlert = getSessionValue(USER_ATTRACTED_TO_ALERT) === 'true';

  // 1. Get the security settings
  // - if the user is logged in
  // - and hasn't seen the alert yet.
  useEffect(() => {
    if (isLoggedIn && !userAttractedToAlert) {
      getSecuritySettings();
    }
  }, [isLoggedIn, userAttractedToAlert]);

  // Reset the alert closed state when the user logs in,
  // as we want to show the alert once per session.
  useEffect(() => {
    const resetUserAttractedToAlert = () => {
      removeSessionValue(USER_ATTRACTED_TO_ALERT);
    };
    window.addEventListener(AUTH_TOKEN_UPDATE_EVENT, resetUserAttractedToAlert);
    return () => window.removeEventListener(AUTH_TOKEN_UPDATE_EVENT, resetUserAttractedToAlert);
  }, []);
};
