/* eslint-disable max-len,i18next/no-literal-string */
import React, { HTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';

import { Dialog, DialogButtonType } from '@ast/magma/components/dialog';
import { useBreakpoint } from '@ast/magma/breakpoints';

export interface AgreementDialogProps extends HTMLAttributes<HTMLDivElement> {
  readonly agreementTitle: string;
  readonly agreementText: string;
  readonly onClose: (buttonType: DialogButtonType) => void;
}

export const AgreementDialog: React.FC<AgreementDialogProps> = ({
  agreementTitle,
  agreementText,
  onClose,
}: AgreementDialogProps) => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint().device === 'mobile';
  const agreementTextSanitized = useMemo(() => sanitizeHtml(agreementText), [agreementText]);

  return (
    <Dialog
      title={agreementTitle}
      data-stable-name="AgreementDialog"
      align={isMobile ? 'bottom' : 'center'}
      fixedTitle={!isMobile}
      onClose={onClose}
      showClose={
        {
          'aria-label':
            t('agreement-dialog.close-button|Agreement close dialog label', 'Close Agreement Dialog'),
        }
      }
      buttons={[
        {
          label: t('agreement-dialog.decline-button|Common dialog Decline button label', 'Decline'),
          type: DialogButtonType.Cancel,
        },
        {
          type: DialogButtonType.OK,
          label: t('agreement-dialog.accept-button|Common dialog Accept button label', 'Accept'),
        },
      ]}
      defaultButton={DialogButtonType.OK}
    >
      <div dangerouslySetInnerHTML={{ __html: agreementTextSanitized }} />
    </Dialog>
  );
};
