import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@ast/magma/components/button';
import { Checkbox } from '@ast/magma/components/checkbox';
import { DialogButtonType } from '@ast/magma/components/dialog';

import { FieldBehaviorMode } from '@app/queryTyping';

import { useCommonValidationMessages } from '@app/common/valiation/commonValidationMessages';

import { FieldComponent, FieldLocator } from '../types';
import { combineNames, getFieldErrorMessage, isAgreementField } from '../utils';

import { AgreementFieldFragment } from '../queries/queryTyping/all-fields';
import { AgreementDialog } from './AgreementField/AgreementDialog';
import { CFFormField } from './common/CFFormField';
import { FormSectionTitle } from './common/FormSectionTitle';

import styles from './AgreementField.pcss';

/**
 * Represents the AgreementField configurable field related to BooleanField data.
 *
 * Used for Agreement field of User Enrollment step Confirmation.
 */
export const AgreementFieldComponent: FieldComponent<AgreementFieldFragment> = ({
  parent,
  field,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation();
  const name = combineNames(parent, field.id);
  const commonValidationMessages = useCommonValidationMessages();
  const error = getFieldErrorMessage(name, errors, commonValidationMessages);

  const [agreementAccepted, setAgreementAccepted] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const handleCloseDialog = useCallback(
    (buttonType : DialogButtonType) => {
      setShowDialog(false);

      if (buttonType === DialogButtonType.OK) {
        setValue(name, true, { shouldValidate: true });
        setAgreementAccepted(true);
      } else if (buttonType === DialogButtonType.Cancel) {
        setValue(name, false, { shouldValidate: true });
        setAgreementAccepted(false);
      }
    },
    [],
  );

  const { onChange, ...registerOptions } = register(name, {
    required: {
      value: field.fieldBehaviorMode === FieldBehaviorMode.REQUIRED,
      message: t(
        'agreement-field.validation.required|Validation message for required rule',
        'You must accept the Terms and Conditions to complete enrollment',
      ),
    },
  });

  return (
    <div>
      <FormSectionTitle
        title={t('agreement-field.title|Agreement field title', 'Agreement')}
      />

      <CFFormField error={error}>
        <>
          <Checkbox
            aria-invalid={!!error}
            label={field.label || ''}
            data-stable-name={`${field.id}Input`}
            checked={agreementAccepted}
            onChange={(e : React.ChangeEvent<HTMLInputElement>) => {
              setAgreementAccepted(e.target.checked);
              onChange(e);
            }}
            {...registerOptions}
          />
          <Button
            look="inlinetext"
            className={styles.textlink}
            onClick={(event) => {
              event.preventDefault();
              setShowDialog(true);
            }}
            data-stable-name={`${field.id}AgreementLink`}
          >
            {t('agreement-field.textlink|Show agreement dialog link message',
              'Read the Terms and Conditions')}
          </Button>
        </>
      </CFFormField>
      { showDialog && (
        <AgreementDialog
          agreementTitle={field.agreementTitle!}
          agreementText={field.agreementText!}
          onClose={handleCloseDialog}
        />
      )}
    </div>
  );
};

export const AgreementFieldLocator: FieldLocator = ({ field }) => (
  isAgreementField(field)
    ? AgreementFieldComponent
    : undefined
);
