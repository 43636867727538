import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Alert } from '@ast/magma/components/alert';

import { WizardStepOverlaySpinner } from '@app/common/components/configurable-wizards/WizardStepOverlaySpinner';
import { FlexContainer } from '@app/common/components/FlexContainer/FlexContainer';
import {
  ConnectedWizardStep,
  FieldDefaultValue,
  NotFieldContentRenderer,
  setFieldUiProps,
  setWizardStepGenericErrors,
  setWizardStepState,
  WizardStepButtonsRenderer,
} from '@app/common/configurable-wizards';
import {
  PasswordFieldWithLockIconComponent,
  PasswordFieldWithLockIconLocator,
} from '@app/common/configurable-wizards/fields/Password/PasswordWithLockIcon';
import { UsernameFieldComponent, usernameFieldId, UsernameLocator } from '@app/common/configurable-wizards/fields/Username';
import { useWizardStepStateDispatchContext } from '@app/common/configurable-wizards/WizardStateDispatchContext';
import { useWizardStepStateContext } from '@app/common/configurable-wizards/WizardStateStateContext';
import { isNullOrUndefined } from '@app/common/utils';

import { PageURL } from '@app/core/widgets/pages';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';
import { LoginLocalSettings } from '@app/widgets/user-login/LoginLocalSettings';
import { UserLoginSessionStorage } from '@app/widgets/user-login/LoginWizard/UserLoginSessionStorage';
import { LoginWizardStepRouterParams } from '@app/widgets/user-login/types';

import { useTetheredLoginLazyQuery } from '../../queries/queryTyping/tethered-login';
import { useGetLoginSettingsQuery } from './queryTyping/get-login-settings';

import { Buttons } from './Buttons';
import { Links } from './Links';
import { LoginModeSwitcher } from './LoginModeSwitcher';
import styles from './styles.pcss';
import { LoginMode } from './types';
import { useFields } from './useFields';
import { WizardStepWithReCaptcha } from './WizardStepWithReCaptcha';

export const UsernamePassword: React.FC<ConnectedWizardStep> = ({ locators, ...restParams }) => {
  const { t } = useUserLoginTranslation();
  const stepState = useWizardStepStateContext();
  const dispatch = useWizardStepStateDispatchContext();
  const stepLocators = [
    UsernameLocator,
    PasswordFieldWithLockIconLocator,
    ...(locators || []),
  ];

  const tetheredLogin = useRouteMatch(`${PageURL.USER_LOGIN}/tethered/:token`);
  const token = (tetheredLogin?.params as LoginWizardStepRouterParams)?.token || '';

  const history = useHistory();
  const [defaultValues, setDefaultValues] = useState<Map<string, FieldDefaultValue>>();

  const isAutoLogoutProceeded = useMemo<boolean>(() => {
    const userLoginSessionStorage = UserLoginSessionStorage.getInstance();
    const autoLogoutRedirection = userLoginSessionStorage.getAutoLogoutRedirection();

    // clear auto-logout data after using
    userLoginSessionStorage.setAutoLogoutRedirection(null);

    return !isNullOrUndefined(autoLogoutRedirection);
  }, []);

  const [getTetheredLogin, {
    data: tetheredLoginQueryData,
  }] = useTetheredLoginLazyQuery();

  useEffect(() => {
    if (token) {
      const variables = {
        tetheredLoginParams: {
          token,
        },
      };

      getTetheredLogin({ variables });

      history.replace(PageURL.USER_LOGIN);
    }
  }, [token]);

  // prefill username if it was passed from tethered login
  useEffect(() => {
    if (tetheredLoginQueryData?.tetheredLogin?.username) {
      const defaultFieldValues = new Map<string, FieldDefaultValue>([
        [
          usernameFieldId, {
            defaultValue: tetheredLoginQueryData.tetheredLogin.username,
            needTriggerChange: true,
          },
        ],
      ]);

      setDefaultValues(defaultFieldValues);
    }
  }, [tetheredLoginQueryData]);

  // used for switching between login modes
  const [loginMode, setLoginMode] = useState<LoginMode>(LoginLocalSettings.instance.prefferableLoginMode);

  // save the user's preferred login mode to local storage
  useEffect(() => {
    LoginLocalSettings.instance.prefferableLoginMode = loginMode;
  }, [loginMode]);

  // get current login settings
  const { loading, data: loginSettingsData } = useGetLoginSettingsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const quickLoginEnabled = loginSettingsData?.loginSettings.quickLoginSettings.isEnabled;
  const passwordVisibilityControlEnabled = loginSettingsData?.loginSettings.passwordVisibilityControlSettings.isEnabled;

  // if the biometric login is disabled, switch user-preferable mode to the password login
  useEffect(() => {
    if (!quickLoginEnabled) {
      setLoginMode(LoginMode.password);
    }
  }, [quickLoginEnabled]);

  // set password field UI props to show/hide password visibility control
  useEffect(() => {
    dispatch(
      setFieldUiProps([
        PasswordFieldWithLockIconComponent, { showPasswordVisibilityControl: passwordVisibilityControlEnabled },
      ]),
    );
  }, [passwordVisibilityControlEnabled, dispatch]);

  // set the focus on the username field by default
  useEffect(() => {
    dispatch(
      setFieldUiProps([
        UsernameFieldComponent, { autoFocus: true },
      ]),
    );
  }, [dispatch]);

  // use an actualized set of fields based on the current login mode
  const fields = useFields({
    loginMode,
    initialFields: stepState.fields,
  });

  // update step state with actualized fields
  useEffect(() => {
    dispatch(setWizardStepState({
      ...stepState,
      fields,
    }));
  }, [fields, dispatch]);

  // clear errors on switching login-mode
  useEffect(() => {
    dispatch(setWizardStepGenericErrors([]));
  }, [loginMode, dispatch]);

  const beforeFieldsContent: NotFieldContentRenderer = ({
    formState,
  }) => {
    const isShowAutoLogoutNotification = () => (
      isAutoLogoutProceeded && !formState.isSubmitted
    );

    return (
      <>
        {isShowAutoLogoutNotification() && (
          <Alert level="info">
            {t(
              'login.steps.username-password.auto-logout-redirection-text|Represents a notification if the user was automatically logout',
              'You have been automatically logged out due to inactivity. Please log in again.',
            )}
          </Alert>
        )}
      </>
    );
  };

  const buttons: WizardStepButtonsRenderer = useCallback(() => (<Buttons />), []);

  return (
    <FlexContainer
      className={styles.flexContainer}
      direction="column"
    >
      {/* Spinner */}
      <WizardStepOverlaySpinner loading={loading} />

      {/* Login moge control */}
      {quickLoginEnabled && (
        <LoginModeSwitcher
          defaultMode={loginMode}
          onLoginModeChange={setLoginMode}
        />
      )}

      {/* Wizard step content */}
      {!loading && (
        <WizardStepWithReCaptcha
          {...restParams}
          buttons={buttons}
          locators={stepLocators}
          beforeFieldsContent={beforeFieldsContent}
          defaultFieldValues={defaultValues}
        />
      )}

      {/* Links to enroll/retrieval */}
      <Links />
    </FlexContainer>
  );
};
