import React, {
  CSSProperties,
  Ref, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { Accordion, AccordionSelectEventArguments } from '@ast/magma/components/accordion';
import { Button } from '@ast/magma/components/button';
import { Card } from '@ast/magma/components/card';
import { Divider } from '@ast/magma/components/divider';
import { disableBodyScroll, enableBodyScroll } from '@ast/magma/components/modal/scrollmanager';
import { Spinner } from '@ast/magma/components/spinner';

import { LocalizationSelectorWrapper } from '@app/common/components/LocalizationSelector/LocalizationSelectorWrapper';
import { LinkMenuItem, MenuItem, useMainMenuQuery } from '@app/common/types/useMainMenuQuery';

import { useCurrentUserInfoContext } from '@app/core/contexts/currentUserInfo/CurrentUserInfoContext';
import { useSelectedMainMenuItemContext } from '@app/core/contexts/selectedMainMenuItem/SelectedMainMenuItemContext';
import { PageURL } from '@app/core/widgets/pages';

import { MobileMenuItemRenderer } from './mobilemenuitem/mobilemenuitem';

import styles from './mobilemenu.pcss';

export interface MobileMenuProps {
  readonly onClose:(item?:LinkMenuItem)=>void;
  readonly onSignOut:()=>void;
  readonly style?: CSSProperties;
}

export const MobileMenu = React.forwardRef(
  (props:MobileMenuProps,
    ref: Ref<HTMLDivElement>) => {
    const { t } = useTranslation('footer');
    const {
      loading, error, categories,
    } = useMainMenuQuery();
    const selectedMainMenuItem = useSelectedMainMenuItemContext();
    const currentUserInfo = useCurrentUserInfoContext();

    if (error) {
      throw error;
    }

    /**
     * Disable body scolling while mobile menu is opened.
     * This code similar to modal dialogs.
     */
    useEffect(() => {
      disableBodyScroll();
      return () => {
        enableBodyScroll();
      };
    }, []);

    const menuGroups = useMemo(() => {
      let result:MenuItem[] = [];
      if (categories != null) {
        result = categories.reduce((groups, category) => {
          groups.splice(groups.length, 0, ...category.menuItems!);
          return groups;
        }, result);
      }

      return result;
    }, [categories]);

    // Find highlighted group based on match any menu item link with current URL
    const highlightedGroupIndex = useMemo(() => (menuGroups?.findIndex(
      (menuGroup:MenuItem) => menuGroup.menuItems?.some((item:LinkMenuItem) => selectedMainMenuItem?.url === item.url),
    )), [menuGroups]);

    const handleSelection = (event: AccordionSelectEventArguments) => {
      if (event.action === 'select') {
        props.onClose(event.data as LinkMenuItem);
      }
    };

    return (
      <Card
        style={props.style}
        ref={ref}
        hasShadow={false}
        hasBorder={false}
        className={classNames(styles.menu)}
      >
        <div className={styles.scrollContainer}>
          <div className={styles.profile}>
            <h6 className={styles.label}>{currentUserInfo?.preferredName}</h6>
            <LocalizationSelectorWrapper preferredCulture={currentUserInfo?.preferredCulture} />
            <Button
              look="inlinetext"
              onClick={() => {
                props.onClose({ url: `${PageURL.CONTACTS_AND_SETTINGS}/profile-security/` } as LinkMenuItem);
              }}
            >
              {t('open-account-setting|Open account settings', 'See your profile')}
            </Button>
            <Button
              look="inlinetext"
              onClick={(event) => {
                event.preventDefault();
                props.onSignOut();
              }}
            >
              {t('signout-setting|Sign out', 'Sign out')}
            </Button>
          </div>
          <Divider className={styles.divider} />
          {
          (loading)
            ? (<Spinner size="xl" />)
            : (
              <Accordion
                expanded={highlightedGroupIndex >= 0 ? [`.${highlightedGroupIndex}`] : undefined}
                expandMode="multiple"
                data={menuGroups}
                className={styles.accordion}
                // eslint-disable-next-line i18next/no-literal-string
                childField="menuItems"
                onSelect={handleSelection}
                itemRenderer={MobileMenuItemRenderer}
              />
            )
        }
        </div>
      </Card>
    );
  },
);
