import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@ast/magma/components/button';
import { PageURL } from '@app/core/widgets/pages';
import { isFunction } from '@app/common/utils';
import { WizardButtonComponent, WizardButtonProps } from '../types';

export const CancelButton: WizardButtonComponent<WizardButtonProps> = ({ title, handler }) => {
  const history = useHistory();

  return (
    <Button
      type="button"
      look="outlined"
      predefinedSize="medium"
      data-stable-name="CancelButton"
      // TODO: Move history.replace(PageURL.USER_LOGIN) to appropriate place
      onClick={isFunction(handler) ? handler : () => history.replace(PageURL.USER_LOGIN)}
    >
      {title}
    </Button>
  );
};
