/* eslint-disable i18next/no-literal-string */
import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import join from 'url-join';

import {
  DEFAULT_FALLBACK_LANGUAGE_CULTURE,
  getCurrentLanguageCultureFromStorage,
  getLanguageFromCulture,
} from '@app/core/contexts/localizationContext/LocalizationContext';

import { getCustomAuthHeaders } from './authentication/customAuthHeaders';

import { appSettings } from './appSettings';

/**
 * Text customization service initialized promise
 */

const currentCulture = getCurrentLanguageCultureFromStorage();
const currentLanguage = getLanguageFromCulture(currentCulture || DEFAULT_FALLBACK_LANGUAGE_CULTURE);

export const initializeI18n = new Promise<void>((resolve) => {
  i18n
    .use({
      type: 'backend',
      read: (language: string, namespace: string, callback: any) => {
        const url = join(
          appSettings().apiEndpoint,
          'api/textcustomization',
        );
        const customAuthHeaders = getCustomAuthHeaders();
        const headers = {
          ...customAuthHeaders,
          'Content-Type': 'application/json',
          'x-ast-lang-namespace': namespace,
        };

        fetch(url, {
          method: 'GET',
          headers,
          credentials: 'include',
          mode: 'cors',
        })
          .then((response) => response.json())
          .then((data) => {
            callback(null, data);
          })
          .catch((error) => {
            callback(error, null);
          });
      },
    })
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      keySeparator: false, // to use dots in strings
      nsSeparator: ':',
      lng: currentLanguage,
      fallbackLng: currentLanguage,
      defaultNS: 'app',
      ns: ['app'],
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });

  if (i18n.isInitialized) {
    resolve();
  } else {
    i18n.on('initialized', () => resolve());
  }
});

const t = i18n.t.bind(i18n);
export { t };

export default i18n;
