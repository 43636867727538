import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Call from '@ast/magma/components/icon/icons/Call';
import Envelope from '@ast/magma/components/icon/icons/Envelope';
import MobileDevice from '@ast/magma/components/icon/icons/MobileDevice';

import { Choice } from '@app/queryTyping';

import { FieldContext, FieldLocator, SingleChoiceField } from '@app/common/configurable-wizards';
import { FieldComponent } from '@app/common/configurable-wizards/types';
import { combineNames } from '@app/common/configurable-wizards/utils';

import {
  IconTitleSubtitleControlSelectorComponent,
  SelectorOption,
} from './IconTitleSubtitleControlSelector/IconTitleSubtitleControlSelector';
import { isSingleChoiceRadioButtonsField } from './SingleChoice/RadioButtons/SingleChoiceRadioButtonsField';

import styles from './DeliveryChannelTypes.pcss';

export const DeliveryChannelTypesFieldComponent: FieldComponent<SingleChoiceField> = ({
  parent,
  field,
}) => {
  const { t } = useTranslation();
  const name = combineNames(parent, field.id);
  const choices = (field.choiceList || []) as Choice[];

  const optionMap = new Map<string, SelectorOption>([
    // eslint-disable-next-line i18next/no-literal-string
    ['Email', {
      // eslint-disable-next-line i18next/no-literal-string
      id: 'Email',
      title: t('otp-channel.email.title|Otp channel email title', 'Email'),
      subtitle: t(
        'otp-channel.email.subtitle|Otp channel email subtitle',
        'Send PIN code to email address',
      ),
      icon: <Envelope className={styles.icon} />,
      priority: 1,
    }],
    // eslint-disable-next-line i18next/no-literal-string
    ['Sms', {
      // eslint-disable-next-line i18next/no-literal-string
      id: 'Sms',
      title: t('otp-channel.sms.title|Otp channel sms title', 'SMS'),
      subtitle: t(
        'otp-channel.sms.subtitle|Otp channel sms subtitle',
        'Send PIN code to mobile phone',
      ),
      icon: <MobileDevice className={styles.icon} />,
      priority: 2,
    }],
    // eslint-disable-next-line i18next/no-literal-string
    ['VoiceCall', {
      // eslint-disable-next-line i18next/no-literal-string
      id: 'VoiceCall',
      title: t('otp-channel.voice-call.title|Otp channel voice call title', 'Voice-call'),
      subtitle: t(
        'otp-channel.voice-call.subtitle|Otp channel voice call subtitle',
        'PIN code via call to a phone number',
      ),
      icon: <Call className={styles.icon} />,
      priority: 3,
    }],

  ]);

  // convert to options
  const otpChannelOptions: SelectorOption[] = useMemo(
    () => choices.map((choice) => (optionMap.get(choice.id)))
      .filter((otpChannel): otpChannel is SelectorOption => !!otpChannel),
    [choices],
  );

  return (
    <IconTitleSubtitleControlSelectorComponent
      id={field.id}
      name={name}
      fieldBehaviorMode={field.fieldBehaviorMode}
      selectorOptions={otpChannelOptions}
    />
  );
};

export const isDeliveryChannelTypesFieldLocator = (fieldContext: FieldContext) => (
  isSingleChoiceRadioButtonsField(fieldContext)
  && fieldContext.field.id === 'DeliveryChannelTypes'
);

export const DeliveryChannelTypesFieldLocator: FieldLocator = (context) => (
  isDeliveryChannelTypesFieldLocator(context)
    ? DeliveryChannelTypesFieldComponent
    : undefined
);
