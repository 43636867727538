import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import sanitizeHtml from 'sanitize-html';

import { Checkbox } from '@ast/magma/components/checkbox';
import dialogStyles from '@ast/magma/components/dialog/dialog.pcss';

import { FieldBehaviorMode } from '@app/queryTyping';

import {
  FormControl,
} from '@app/common/components/controls/FormControl';
import {
  formControlRequiredRule,
} from '@app/common/components/controls/formControlValidation';
import {
  AgreementField,
  FieldComponent,
  FieldLocator,
  isAgreementField,
} from '@app/common/configurable-wizards';
import { useCommonValidationMessages } from '@app/common/valiation/commonValidationMessages';

import { getFieldErrorMessage } from '../../utils';

import styles from './TermsAndConditionsField.pcss';

export const fieldName = 'TermsAndConditions';

export const TermsAndConditionsFieldComponent: FieldComponent<AgreementField> = ({
  field,
}) => {
  const { t } = useTranslation();
  const { formState: { errors } } = useFormContext();
  const [isAgreementDisabeld, setIsAgreementDisabeld] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);
  const required = field.fieldBehaviorMode === FieldBehaviorMode.REQUIRED;

  useEffect(() => {
    const container = contentRef.current;

    if (container) {
      const { scrollHeight, offsetHeight } = container;

      // if content fits on one page, enable agreement checkbox
      if (scrollHeight <= offsetHeight) {
        setIsAgreementDisabeld(false);
      }
    }
  }, []);

  const agreementTextSanitized = useMemo(
    () => field.agreementText && sanitizeHtml(field.agreementText, {
      allowedTags: [
        ...sanitizeHtml.defaults.allowedTags,
        // eslint-disable-next-line i18next/no-literal-string
        'img',
      ],
    }),
    [field.agreementText],
  );

  const validationRules = useMemo(() => [
    ...(required ? [formControlRequiredRule(t(
      'terms-and-conditions-field.validation.required|Validation message for terms and conditions field required value',
      'This field is required',
    ))] : []),
  ], [required]);

  const commonValidationMessages = useCommonValidationMessages();
  const getErrorMessage = useCallback(
    () => getFieldErrorMessage(fieldName, errors, commonValidationMessages),
    [errors],
  );

  const handleRead = () => {
    const container = contentRef.current;

    if (container && isAgreementDisabeld) {
      const { scrollHeight, offsetHeight, scrollTop } = container;

      // if end is reached, enable agreement checkbox
      if (Math.abs(scrollHeight - scrollTop - offsetHeight) < 1) {
        setIsAgreementDisabeld(false);
      }
    }
  };

  return (
    <>
      <h5 className={dialogStyles.title}>{field.agreementTitle}</h5>
      {agreementTextSanitized && (
        <div className={styles.wrapper}>
          <div
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            role="document"
            ref={contentRef}
            onScroll={handleRead}
            className={styles.content}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: agreementTextSanitized }}
          />
        </div>
      )}
      <FormControl
        name={fieldName}
        validations={validationRules}
        getErrorMessage={getErrorMessage}
        className={styles.formControl}
      >
        <Checkbox
          label={field.label || ''}
          data-stable-name={`${field.id}Input`}
          disabled={isAgreementDisabeld}
        />
      </FormControl>
    </>
  );
};

export const TermsAndConditionsFieldLocator: FieldLocator = ({ field }) => (
  isAgreementField(field)
    ? TermsAndConditionsFieldComponent
    : undefined
);
