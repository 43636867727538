import React, {
  forwardRef,
  HTMLAttributes,
  MutableRefObject,
  Ref,
  useEffect,
  useMemo,
} from 'react';

import { Spinner } from '@ast/magma/components/spinner';

import useForwardedRef from '@ast/magma/utils/useForwardedRef';

import {
  GetDynamicWidgetQuery,
  useGetDynamicWidgetQuery,
} from '@app/core/widgets/queries/queryTyping/getDynamicWidget';
import { isOrpeusWidget } from '@app/core/widgets/react/orpheusWidget';
import { WidgetType } from '@app/core/widgets/react/types';

import { useAppWidgetsContext } from '../../contexts/appWidgets/AppWidgetsContext';
import {
  GetApplicationWidgetsQuery,
  useGetApplicationWidgetsQuery,
} from '../../widgets/queries/queryTyping/getApplicationWidgets';
import { widgetsMap } from '../../widgets/react/widgets';
import { usePagePath } from '../Application/usePagePath';

import { ZoneName } from './constants';

const getMiddleZones = (widgets: WidgetType[] = []) => {
  const orpheusWidgetIndex = widgets.findIndex(isOrpeusWidget);

  if (orpheusWidgetIndex === -1) {
    return {
      premiddle: [],
      middle: widgets,
      postmiddle: [],
    };
  }

  return {
    premiddle: widgets.slice(0, orpheusWidgetIndex),
    middle: [widgets[orpheusWidgetIndex]],
    postmiddle: widgets.slice(orpheusWidgetIndex + 1, widgets.length),
  };
};

const mergeWidgets = (
  globalWidgets: GetApplicationWidgetsQuery['widgets'],
  pageWidgetsData: GetDynamicWidgetQuery['pages'],
) => {
  const pageWidgets = pageWidgetsData?.[0]?.widgets;

  const widgets: Record<string, WidgetType[]> = {
    header: [],
    footer: [],
    headerbanner: (pageWidgets?.header as WidgetType[]) || [],
    footerbanner: (pageWidgets?.footer as WidgetType[]) || [],
    rightside: (pageWidgets?.rightside as WidgetType[]) || [],
    ...getMiddleZones(pageWidgets?.middle as WidgetType[]),
  };

  return globalWidgets?.reduce((acc, widget) => {
    const zone = widget?.zoneName?.toLowerCase()!;
    if (acc[zone]) {
      acc[zone] = (widget?.items as WidgetType[]).concat(acc[zone]);
    }
    return acc;
  }, widgets);
};

/**
 * Widget zone component properties.
 */
export interface WidgetZoneProps {
  /**
   * Widget zone name.
   */
  readonly zoneName: ZoneName;

  /**
   * Callback on widgets are rendered.
   */
  readonly onRenderWidgets?: (widgetCount: number) => void;
}

const WidgetWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <div className="widget-container">{children}</div>;

export const useZoneWidgets = (zoneName: ZoneName) => {
  const { loading: loadingGlobalWidgets } = useGetApplicationWidgetsQuery();
  const appWidgetsContext = useAppWidgetsContext();
  const { pageId } = usePagePath();

  const { data: pageWidgetsData, loading } = useGetDynamicWidgetQuery({
    variables: { id: pageId! },
    skip: !pageId,
  });
  const widgetsList = useMemo(
    () => mergeWidgets(appWidgetsContext?.widgets!, pageWidgetsData?.pages!)?.[
      zoneName.toLowerCase()
    ] || [],
    [appWidgetsContext, pageWidgetsData, zoneName],
  );

  const widgets = widgetsList
    .map((widget, index) => {
      const [_, Component] = [...widgetsMap].find(([checker]) => checker(widget)) || [];
      if (Component) {
        return (
          <WidgetWrapper key={`${widget.__typename}${index}`}>
            <Component widget={widget} zoneName={zoneName} />
          </WidgetWrapper>
        );
      }
      // eslint-disable-next-line no-underscore-dangle
      console.error(
        `Unknown widget type for widget "${widget?.__typename}" in zone "${zoneName}".`,
      );
      return null;
    })
    .filter((component) => !!component);

  const needLayout = zoneName === ZoneName.Middle && !widgetsList.find(isOrpeusWidget);

  return { widgets, loading: loading || loadingGlobalWidgets, needLayout };
};

export const WidgetZone = React.memo(forwardRef(
  (
    props: WidgetZoneProps & HTMLAttributes<HTMLDivElement>,
    ref: Ref<HTMLDivElement>,
  ) => {
    const { zoneName, onRenderWidgets, ...otherDivProps } = props;

    const zoneContainerRef: MutableRefObject<HTMLDivElement | null> = useForwardedRef<HTMLDivElement>(ref);
    const { widgets } = useZoneWidgets(zoneName);

    useEffect(() => {
      if (onRenderWidgets) onRenderWidgets(widgets.length);
    }, [widgets]);

    return (
      <React.Suspense fallback={<Spinner size="md" />}>
        <div
          ref={zoneContainerRef}
          widget-zone={zoneName}
          {...otherDivProps}
          data-stable-name="WidgetZone"
        >
          {widgets}
        </div>
      </React.Suspense>
    );
  },
));
