import { handleMessage } from './handleMessage';
import { handlers } from './handlers';

/**
 * Initializes Agile SDK that listens for window events and reacts on them.
 *
 * For example, the SDK allows apps inside of iframes to invoke backend components
 * and receive data from the backend.
 */
export async function initializeAgileSDK() {
  window.addEventListener('message', (event: MessageEvent<unknown>) => {
    handleMessage({
      source: event.source as Window,
      origin: event.origin,
      data: event.data,
    }, handlers)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Agile SDK error:', error);
      });
  }, false);
}
