import React from 'react';

import { FullscreenLayout } from '@app/common/layouts/FullscreenLayout/FullscreenLayout';

import { getNotFoundError } from '@app/core/widgets/pages';

import { ZoneName } from '../WidgetZone/constants';
import { useZoneWidgets } from '../WidgetZone/WidgetZone';

export const Page = React.memo(() => {
  const { widgets, loading, needLayout } = useZoneWidgets(ZoneName.Middle);

  if (widgets.length === 0 && !loading) throw getNotFoundError();

  return needLayout && !loading ? (
    <FullscreenLayout removeHeaderSection>
      {widgets}
    </FullscreenLayout>
  ) : <>{widgets}</>;
});
