import React, { HTMLAttributes, useMemo } from 'react';

import classNames from 'classnames';
import * as CSS from 'csstype';

import { ClassValue } from 'classnames/types';

import { flexGapSupported } from '@app/common/utils/isCSSFlexGapSupported';

import styles from './FlexContainer.pcss';

/**
 * Supported gaps
 */
type Gap = 'default' | 'extraSmall' | 'small' | 'large' | 'extraLarge' | 'none';

export interface FlexContainerProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * flex direction CSS property
   */
  readonly direction?: CSS.Property.FlexDirection,
  /**
   * flex gap size type
   */
  readonly gap?: Gap,
  /**
   * flex wrap mode
   */
  readonly wrap?: boolean,
}

const gapClassMap = new Map<Gap, string>([
  // eslint-disable-next-line i18next/no-literal-string
  ['default', styles.defaultGap],
  // eslint-disable-next-line i18next/no-literal-string
  ['extraSmall', styles.extraSmallGap],
  // eslint-disable-next-line i18next/no-literal-string
  ['small', styles.smallGap],
  // eslint-disable-next-line i18next/no-literal-string
  ['large', styles.largeGap],
  // eslint-disable-next-line i18next/no-literal-string
  ['extraLarge', styles.extraLargeGap],
]);

/**
 * Simple flex container
 * @example
 * // vertical orientation with small gap
 * <FlexContainer direction="column" gap="small"  ... />
 * @example
 * // horizontal orientation without gap
 * <FlexContainer direction="row" ... />
 */
export const FlexContainer: React.FC<FlexContainerProps> = ({
  direction,
  gap,
  wrap,
  children,
  ...htmlProps
}) => {
  const { className, style, ...restHTMLProps } = htmlProps;

  const isFlexGapSupported = useMemo(flexGapSupported, []);

  const cssClasses = useMemo(() => {
    const classes: Array<ClassValue> = [
      className,
      styles.container,
      wrap && styles.wrap,
      gap && gapClassMap.get(gap),
    ];

    // add flex gap workaround based on CSS margins
    // mostly to support iOS 14.5/Safari 14.1 and lower versions
    // https://webkit.org/blog/11648/new-webkit-features-in-safari-14-1/
    if (!isFlexGapSupported) {
      switch (direction) {
        case 'column':
          classes.push(styles.verticalMarginGap);
          break;
        case 'column-reverse':
          classes.push(styles.verticalMarginGapReverse);
          break;
        case 'row':
          classes.push(styles.horizontalMarginGap);
          break;
        case 'row-reverse':
          classes.push(styles.horizontalMarginGapReverse);
          break;
        default:
          break;
      }
    }

    return classNames(...classes);
  }, [
    isFlexGapSupported,
    className,
    direction,
    gap,
  ]);

  return (
    <div
      style={{
        flexDirection: direction,
        ...style,
      }}
      className={cssClasses}
      {...restHTMLProps}
    >
      {children}
    </div>
  );
};

FlexContainer.defaultProps = {
  direction: 'row',
  gap: 'default',
  wrap: true,
};
