import React from 'react';

import { FlexContainer } from '@app/common/components/FlexContainer/FlexContainer';

import { PageURL } from '@app/core/widgets/pages';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import { LoginLink } from './LoginLink';

export const Links: React.FC = () => {
  const { t } = useUserLoginTranslation();

  return (
    <FlexContainer direction="column" gap="small">
      <LoginLink href={PageURL.USER_RETRIEVAL} data-stable-name="ForgotPasswordLink">
        {t('login.steps.username-password.links.password-retrieval.text|Password retrieval link text',
          'Forgot username/password?')}
      </LoginLink>
      <LoginLink href={PageURL.USER_ENROLLMENT} data-stable-name="EnrollmentLink">
        {t('login.steps.username-password.links.user-enroll.text|User enrollment link text',
          'Don\'t have an account? Enroll')}
      </LoginLink>
    </FlexContainer>
  );
};
