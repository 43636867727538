let isSupported: boolean;

export const flexGapSupported = () => {
  // returns result immediately if checked before
  if (typeof isSupported !== 'undefined') {
    return isSupported;
  }

  // create flex container with row-gap set
  const flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px';

  // create two, elements inside it
  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));

  // append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex);
  isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap

  if (flex.parentNode) {
    flex.parentNode.removeChild(flex);
  }

  return isSupported;
};
