/* eslint-disable react/destructuring-assignment */
import React, { FC } from 'react';

import classNames from 'classnames';

import EmptyMenu from '@ast/magma/components/icon/icons/EmptyMenu';

import { Button } from '@ast/magma/components/button';
import { ClippedTextBox } from '@ast/magma/components/clippedTextBox';

import { MenuItemEligibility } from '@app/core/components/MenuNavigation/MenuItemEligibility';

import { LinkMenuItem } from '@app/common/types/useMainMenuQuery';
import { getMenuIcon } from '@app/common/utils/iconsProvider';

import styles from './quicklaunchitem.pcss';

export interface QuickLaunchItemProps {
  readonly selected:boolean;
  readonly data: LinkMenuItem;
  readonly className?: string;
  readonly menuId: string;
  readonly onSelect: (item:LinkMenuItem) => void;
}

export const QuickLaunchItem:FC<QuickLaunchItemProps> = (props) => {
  const {
    selected, data, className, onSelect, menuId,
  } = props;

  return (
    <MenuItemEligibility
      menuItem={data}
    >
      <Button
        data-stable-name={`QuickLaunchItemButton`}
        look="link"
        icon={data.icon ? getMenuIcon(data.icon) : <EmptyMenu />}
        iconPosition="top"
        className={classNames(styles.button, className, selected && styles.selected)}
        onClick={() => onSelect(data)}
      >
        <ClippedTextBox className={styles.label}>{ data.linkName }</ClippedTextBox>
      </Button>
    </MenuItemEligibility>
  );
};
