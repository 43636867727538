type FunctionType = (
  this: any,
  ...args: Parameters<typeof window.history.pushState>
) => ReturnType<typeof window.history.pushState>;

const overrideHistoryMethod = (methodName: 'pushState' | 'replaceState'): FunctionType => {
  const originalFunction = window.history[methodName];
  // eslint-disable-next-line func-names
  return function(
    this: any, ...args: Parameters<typeof originalFunction>
  ): ReturnType<typeof originalFunction> {
    const result = originalFunction.apply(this, args);
    window.dispatchEvent(new Event(methodName.toLowerCase()));
    window.dispatchEvent(new Event('locationchange'));
    return result;
  };
}

export const onLocationChange = (handler: () => void) => {
  window.addEventListener('popstate', handler);
  window.addEventListener('locationchange', handler);

  window.history.pushState = overrideHistoryMethod('pushState');
  window.history.replaceState = overrideHistoryMethod('replaceState');
};
