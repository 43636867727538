import { useMemo, useRef } from 'react';

import { FieldType } from '@app/queryTyping';

import { Field, Fields } from '@app/common/configurable-wizards';

import { LoginMode } from './types';

type LoginModeFieldAdapter = (fields: readonly Field[]) => readonly Field[];
type LoginModeFieldAdapters = Record<LoginMode, LoginModeFieldAdapter>;

const setLoginModeValue = (fields: readonly Field[], mode: LoginMode) => {
  const loginMode = fields.find(({ id }) => id === 'LoginMode') as Fields['Hidden'];
  if (loginMode) {
    loginMode.sValue = mode === LoginMode.biometric ? 'Quick' : null;
  } else {
    // eslint-disable-next-line i18next/no-literal-string
    throw new Error('LoginMode field not found');
  }
};

const makePasswordHidden = (fields: readonly Field[]): readonly Field[] => {
  const newFields: Field[] = [...fields];

  // make password field hidden
  const passwordIndex = fields.findIndex(({ id }) => id === 'Password');
  if (passwordIndex) {
    const hiddenPassword: Fields['Hidden'] = {
      ...fields[passwordIndex],
      __typename: 'HiddenFieldInfo',
      type: FieldType.HIDDENFIELDINFO,
      sValue: '',
    };

    newFields.splice(passwordIndex, 1);
    newFields.push(hiddenPassword);
  }

  return newFields;
};

const loginModeFieldAdapters: LoginModeFieldAdapters = {
  [LoginMode.password]: (fields) => {
    setLoginModeValue(fields, LoginMode.password);
    return fields;
  },
  [LoginMode.biometric]: (fields) => {
    const newFields = makePasswordHidden(fields);
    setLoginModeValue(newFields, LoginMode.biometric);
    return newFields;
  },
};

export interface UseFieldsProps {
  loginMode: LoginMode
  initialFields: readonly Field[]
}

/**
 * Actualize fields based on the current login mode
 */
export const useFields = ({ loginMode, initialFields }: UseFieldsProps) => {
  const initialFieldsRef = useRef<readonly Field[]>(initialFields);

  return useMemo(
    () => loginModeFieldAdapters[loginMode](initialFieldsRef.current),
    [loginMode],
  );
};
