import React, {
  FC, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import GlobeIcon from '@ast/magma/components/icon/icons/Globe';

import { DropDownMenu } from '@ast/magma/components/dropdownmenu';
import { Menu, MenuItem } from '@ast/magma/components/menu';

import { useIsMobile } from '@app/common/utils/hooks/useIsMobile';

import { useIsLoggedIn } from '@app/core/authentication/login/isLoggedIn';
import { getLanguageFromCulture } from '@app/core/contexts/localizationContext/LocalizationContext';

import styles from './LocalizationSelector.pcss';

type MenuItemValue = {
  label?: string,
  value?: string
};

export interface LocalizationSelectorProps {
  readonly loading: boolean;
  readonly items: MenuItemValue[];
  readonly currentCulture: string | null;
  readonly onCurrentItemChange?: (item: MenuItemValue) => void
}

export const LocalizationSelector: FC<LocalizationSelectorProps> = ({
  items,
  loading,
  currentCulture,
  onCurrentItemChange,
}: LocalizationSelectorProps) => {
  const { t } = useTranslation('localization-selector');

  const loggedIn = useIsLoggedIn();
  const isMobile = useIsMobile();

  const isLoggedInDesktop = loggedIn && !isMobile;
  const isLoggedInMobile = loggedIn && isMobile;

  const getCultureItem = (code: string | null) => items
    .find((item) => {
      return item.value === code || (code && item.value === getLanguageFromCulture(code));
    }) ?? null;

  const [
    selectedItem,
    setSelectedItem,
  ] = useState<MenuItemValue | null>(getCultureItem(currentCulture));

  const [
    isDropDownOpened,
    setIsDropDownOpened,
  ] = useState<boolean>(false);
  const handleItemClick = (item:MenuItemValue): void => {
    if (item.value === selectedItem?.value) {
      return;
    }
    setSelectedItem(item);
    setIsDropDownOpened(false);
    if (onCurrentItemChange) {
      onCurrentItemChange(item);
    }
  };
  const handleOpenChange = (newOpen:boolean) => setIsDropDownOpened(newOpen);

  const getButtonLabel = () => (loading
    ? t('localization-selector.loading.label|Button loading label', 'Changing language')
    : selectedItem?.label
  );

  return (
    <DropDownMenu
      hideArrow={isLoggedInDesktop}
      icon={isLoggedInDesktop || !loggedIn
        ? (
          <GlobeIcon
            className={styles.dropDownListButtonIcon}
          />
        )
        : <></>}
      dropHorizontalAlignment={isLoggedInMobile ? 'left' : 'right'}
      className={classNames(styles.dropDownList, !loggedIn && styles.isNotLoggedIn, loggedIn && styles.loggedIn)}
      data-stable-name="LocalizationSelectorLabel"
      aria-label={getButtonLabel()}
      isOpen={isDropDownOpened}
      dropWidth="unset"
      onOpenChange={handleOpenChange}
      text={isLoggedInMobile || !loggedIn ? getButtonLabel() : undefined}
    >
      <Menu
        className={styles.menu}
        data-stable-name="LocalizationSelectorMenu"
        autoFocus={isDropDownOpened}
        selectedItem={selectedItem}
      >
        { items.map((item) => (
          <MenuItem
            data-stable-name={`LocalizationSelectorMenu-${item.value}-Item`}
            onClick={() => handleItemClick(item)}
            key={item.value}
            value={item.value}
            data={item.label}
            aria-selected={item.value === selectedItem?.value}
          />
        ))}
      </Menu>
    </DropDownMenu>
  );
};
