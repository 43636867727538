import React from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormFieldSet } from '@ast/magma/components/formfieldset';
import { Radio } from '@ast/magma/components/radio';

import { Choice, FieldBehaviorMode } from '@app/queryTyping';

import { SingleChoiceField } from '@app/common/configurable-wizards';
import {
  FieldComponent,
  FieldLocator,
  FieldContext,
} from '@app/common/configurable-wizards/types';
import { combineNames } from '@app/common/configurable-wizards/utils/combineNames';
import { getFieldErrorMessage } from '@app/common/configurable-wizards/utils/fieldErrors';
import { isSingleChoiceField } from '@app/common/configurable-wizards/utils/fieldTypesCheck';
import { FormValidationOptions } from '@app/common/types/form';
import { useCommonValidationMessages } from '@app/common/valiation/commonValidationMessages';

import { GetDefaultValue, GetName } from '../../RadioGroupField';

/**
 * Returns value of the default selected value
 */
export const getSingleChoiceFieldDefaultValue:
GetDefaultValue<SingleChoiceField> = (field) => field.selectedValue || '';

/**
* Returns field name
*/
export const getSingleChoiceFieldName: GetName<SingleChoiceField> = (parent, field) => (
  combineNames(parent, field.id)
);

/**
 * Represents the SingleChoiceFieldInfo configurable field.
 *
 * Contains a set of <Radio> components.
 * Does not select any value on appear.
 * Used for 'branch where opened account' field of User Enrollment.
 * Also used for 'Delivery address selection step' (2nd step of OTP process).
 */
export const SingleChoiceRadioButtonsFieldComponent: FieldComponent<SingleChoiceField> = ({
  parent,
  field,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { t } = useTranslation();
  const combinedName = getSingleChoiceFieldName(parent, field);
  const commonValidationMessages = useCommonValidationMessages();
  const error = getFieldErrorMessage(combinedName, errors, commonValidationMessages);

  // The data options
  const choices = (field.choiceList || []) as Choice[];

  const validationRules: FormValidationOptions = {
    required: (field.fieldBehaviorMode === FieldBehaviorMode.REQUIRED)
      && t('single-choice.validation.required|Validation message', 'Select one of the options').toString(),
  };

  const getBody = ({ onChange, ref, name }: ControllerRenderProps) => (
    choices.map((radioButton) => (
      <div key={radioButton.id}>
        <Radio
          name={name}
          value={radioButton.id}
          defaultChecked={getSingleChoiceFieldDefaultValue(field) === radioButton.id}
          label={radioButton.text || ''}
          data-stable-name={combineNames(field.id, radioButton.id)}
          onChange={onChange}
          ref={ref}
        />
      </div>
    ))
  );

  return (
    <Controller
      name={combinedName}
      data-stable-name="SingleChoiceRadioButtonsController"
      render={(renderProps) => (
        <FormFieldSet
          error={error}
          legend={field.label || ''}
          reserveErrorHeight={false}
          data-stable-name={`${field.id}FormFieldSet`}
          aria-invalid={renderProps.fieldState.invalid}
          defaultValue={renderProps.field.value}
        >
          {getBody(renderProps.field)}
        </FormFieldSet>
      )}
      control={control}
      rules={validationRules}
    />
  );
};

export const isSingleChoiceRadioButtonsField = ({ field }: FieldContext) => (
  isSingleChoiceField(field)
);

export const SingleChoiceRadioButtonsFieldInfoLocator: FieldLocator = (context) => (
  isSingleChoiceRadioButtonsField(context)
    ? SingleChoiceRadioButtonsFieldComponent
    : undefined
);
