import { Field, FieldContext, FieldData } from '@app/common/configurable-wizards/types';

export enum ServerSectionIds {
  ADDRESS = 'AddressList',
  MOBILE_PHONE = 'MobilePhoneList',
  HOME_PHONE = 'HomePhoneList',
  WORK_PHONE = 'WorkPhoneList',
  EMAIL = 'EmailList',
  LOGIN = 'LoginInformation',
}

export enum SectionIDs {
  ADDRESS = 'address',
  PHONE = 'phone',
  EMAIL = 'email',
  PROFILE_SECURITY = 'profile-security',
}

export enum EmptyFields {
  EMAIL = 'email',
  HOMEPHONE = 'homePhone',
  WORKPHONE = 'workPhone',
  MOBILEPHONE = 'mobilePhone',
  ADDRESS = 'address',
}

export enum AddressFields {
  AddressList = 'AddressList',
  MailingAddressList = 'MailingAddressList',
}

export enum AddressFieldGroups {
  AddressGroups,
}

export enum AddressItemFields {
  AddressGroup = 'AddressGroup',
  MilitaryMailingAddressGroup = 'MilitaryMailingAddressGroup',
}

export enum AddressTypes {
  Physical = 'Physical',
  Mailing = 'Mailing',
  MilitaryMailing = 'MilitaryMailing',
}

export enum InternationalAddressFields {
  Region = 'Region',
  PostalCode = 'PostalCode',
}

export enum USAddressFields {
  State = 'State',
  ZIPCode = 'ZIPCode',
}

export enum PhoneFields {
  MobilePhoneList,
  HomePhoneList,
  WorkPhoneList,
}

export enum PhoneItemFields {
  MobilePhoneFieldGroup,
  HomePhoneFieldGroup,
  WorkPhoneFieldGroup,
}

export enum EmailFields {
  EmailList,
}

export enum ProfileDetailsFields {
  LoginInformation,
}

export enum PasswordFields {
  PasswordReset = 'PasswordReset',
}

export enum PasswordResetFields {
  OldUserPassword = 'OldUserPassword',
  NewUserPassword = 'NewUserPassword',
}

export enum AddressRecordFields {
  StreetAddress = 'StreetAddress',
  StreetAddress2 = 'StreetAddress2',
  City = 'City',
  State = 'State',
  ZIPCode = 'ZIPCode',
}

export interface UrlParams {
  readonly sectionId: string,
  readonly fieldId: string,
}

export type ConditionalField = Field & {
  readonly skipConditionalField?: boolean;
};

export type ConditionalFieldContext = Omit<FieldContext, 'field'> & {
  readonly field: ConditionalField;
};

export type AddressRecord = Record<AddressRecordFields, string>;
export type AddressLines = [string, string, string, string];

export type AddressFormCache = {
  [AddressItemFields.AddressGroup]: Record<string, FieldData>
};
