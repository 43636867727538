import { ErrorTypes } from '@app/common/errors/errorProcessors/constants';

import i18n from '@app/core/i18n';

import { isErrorNonRenderable } from '../errorTypeChecker';
import { UserError } from '../UserError';

const t = i18n.getFixedT(null, 'app');

export const getGenericErrorMessage = () => ({
  title: t('unexpectedError.title|Unexpected error dialog title', 'Application error'),
  message: t('unexpectedError.message|Unexpected error message', 'Unexpected error occurred.'),
});

/**
 * Generic error processor always return user error.
 * @param error any error.
 */
// eslint-disable-next-line arrow-body-style
export const genericErrorProcessor = (error:Error):Error => {
  if (isErrorNonRenderable(error)) return error;

  return new UserError({
    critical: true,
    title: getGenericErrorMessage().title,
    message: getGenericErrorMessage().message,
    originalError: error,
    type: ErrorTypes.ApplicationError,
  });
};
