import React, { useEffect, useState, FunctionComponent } from 'react';

import { MessageReceiver } from '@ast/shared-message-receiver';

import { createGenericContext } from '@app/common/contexts/createGenericContext';
import {isEmbeddedMode} from "@app/core/authentication/embeddedMode/embeddedMode";
import {
  extractInitParametersFromLocalStorage
} from "@app/core/authentication/parametrizedLogin/sessionParametersStorage";

export interface ILocalizationSelectorContext {
  currentLanguageCulture: string | null;
  setCurrentLanguageCulture: (language: string) => void;
}

export const DEFAULT_FALLBACK_LANGUAGE: string = 'en';
export const DEFAULT_FALLBACK_LANGUAGE_CULTURE: string = 'en';
export const LANGUAGE_CULTURE_KEY: string = 'ast.orpheus.app.culture-id';

export const SEARCH_LANGUAGE_CULTURE_PARAM = 'culture-id';

const [
  useLocalizationContext,
  LocalizationContextProvider,
] = createGenericContext<ILocalizationSelectorContext>();

export const setCurrentLanguageCultureToStorage = (cultureID: string) => {
  localStorage.setItem(LANGUAGE_CULTURE_KEY, cultureID);
};

export const getCurrentLanguageCultureFromStorage = () => localStorage.getItem(LANGUAGE_CULTURE_KEY) ?? null;

export const notifyCurrentCultureId = (cultureId: string) => {
  const messageReceiver = new MessageReceiver({
    source: window,
    origin: '*',
  });
  messageReceiver.postMessage({
    version: '1.0',
    messageType: 'changeCultureId',
    payloadVersion: '1.0',
    payload: {
      cultureId,
    },
  }).then();
};

export const getLanguageFromCulture = (culture: string) => culture?.split('-')[0] || DEFAULT_FALLBACK_LANGUAGE;

export const setHTMLLangAttribute = (culture: string) => {
  document.documentElement.lang = getLanguageFromCulture(culture);
};

export const LocalizationProvider: FunctionComponent = ({ children }) => {
  const currentCultureFromUrl = new URLSearchParams(window.location.search);

  const [currentLanguageCulture, setCurrentLanguageCultureState] = useState<string>(
    currentCultureFromUrl.get(SEARCH_LANGUAGE_CULTURE_PARAM)
    || isEmbeddedMode() && extractInitParametersFromLocalStorage().cultureId
    || getCurrentLanguageCultureFromStorage()
    || getLanguageFromCulture(window.navigator.language)
    || DEFAULT_FALLBACK_LANGUAGE_CULTURE,
  );

  useEffect(() => {
    setHTMLLangAttribute(currentLanguageCulture);
    notifyCurrentCultureId(currentLanguageCulture);
    setCurrentLanguageCultureToStorage(currentLanguageCulture);
  }, [currentLanguageCulture]);

  return (
    <LocalizationContextProvider value={{
      currentLanguageCulture,
      setCurrentLanguageCulture: setCurrentLanguageCultureState,
    }}
    >
      {children}
    </LocalizationContextProvider>
  );
};

export {
  useLocalizationContext,
};
