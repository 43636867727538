import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Lock from '@ast/magma/components/icon/icons/Lock';

import { FieldBehaviorMode } from '@app/queryTyping';

import { PasswordControl } from '@app/common/components/controls/PasswordControl/PasswordControl';
import { useCommonValidationMessages } from '@app/common/valiation/commonValidationMessages';

import { TextFieldFragment as TextField } from '../../queries/queryTyping/all-fields';
import {
  FieldComponent, FieldLocator, FieldUiProps,
} from '../../types';
import { combineNames, isPasswordField, getFieldErrorMessage } from '../../utils';
import { CFFormField } from '../common/CFFormField';

import { ShowHidePasswordButton } from './ShowHidePasswordButton';

export interface PasswordFieldWithLockIconUiProps extends FieldUiProps {
  readonly showPasswordVisibilityControl?: boolean;
}

export const PasswordFieldWithLockIconComponent: FieldComponent<TextField, PasswordFieldWithLockIconUiProps> = ({
  parent,
  field,
  fieldUiProps,
}) => {
  const { t } = useTranslation();
  const { register, formState: { errors } } = useFormContext();
  const name = combineNames(parent, field.id);
  const commonValidationMessages = useCommonValidationMessages();
  const error = getFieldErrorMessage(name, errors, commonValidationMessages);
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <CFFormField error={error}>
      <PasswordControl
        label={field.label || ''}
        leadingChild={<Lock />}
        trailingChild={fieldUiProps?.showPasswordVisibilityControl ? (
          <ShowHidePasswordButton
            title={showPassword
              ? t('password-field.password-visibility-control.hide-password-title|Title text to describe the hide password action (often shown on hover)', 'Hide password')
              : t('password-field.password-visibility-control.show-password-title|Title text to describe the show password action (often shown on hover)', 'Show password')}
            aria-label={showPassword
              ? t('password-field.password-visibility-control.hide-password-aria-label|Alternative text to describe the hide password action (useful for screen readers)', 'Hide password')
              : t('password-field.password-visibility-control.show-password-aria-label|Alternative text to describe the show password action (useful for screen readers)', 'Show password')}
            onShowHidePassword={(shown) => setShowPassword(shown)}
          />
        ) : undefined}
        // todo: move autocomplete to the higher level making this component more generic
        autoComplete="current-password"
        data-stable-name={`${field.id}Input`}
        defaultValue={field.sValue || ''}
        type={showPassword ? 'text' : 'password'}
        {...register(name, {
          required: field.fieldBehaviorMode === FieldBehaviorMode.REQUIRED,
        })}
      />
    </CFFormField>
  );
};

export const PasswordFieldWithLockIconLocator: FieldLocator = ({ field }) => (
  isPasswordField(field)
    ? PasswordFieldWithLockIconComponent
    : undefined
);
