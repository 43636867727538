import React, {
  FC, forwardRef, HTMLAttributes, Ref,
} from 'react';
import { useTranslation } from 'react-i18next';

import Times from '@ast/magma/components/icon/icons/Times';

import { Button } from '@ast/magma/components/button';

import { CenterAlignContainer } from '@app/common/components/CenterAlignContainer/CenterAlignContainer';

import styles from './mainnavpopup.pcss';

/**
 * Popup container properties.
 */
export interface MainNavigationPopupProps {
  readonly onClose: () => void;
}

/**
 * Popup menu navigation container.
 */
export const MainNavigationPopup = forwardRef(({
  onClose,
  children,
  ...divProps
}:MainNavigationPopupProps & HTMLAttributes<HTMLDivElement>,
ref: Ref<HTMLDivElement>) => {
  const { t } = useTranslation('header');

  return (
    <CenterAlignContainer className={styles.paddings}>
      <div className={styles.container} {...divProps} ref={ref}>
        <div className={styles.mainNav}>
          { children }
        </div>
        <Button
          look="icon"
          onClick={onClose}
          icon={<Times />}
          aria-label={
            t('main-navigation-popup.close-menu-button-aria-label|Close menu button aria label', 'Close menu')
          }
        />
      </div>
    </CenterAlignContainer>
  );
});

export default MainNavigationPopup;
